const darkModeActions = (target: Element) => {
    const hasDarkMode = () => target.classList.contains('dark');
    const setDarkMode = () => target.classList.add('dark');
    const removeDarkMode = () => target.classList.remove('dark');
    return { hasDarkMode, setDarkMode, removeDarkMode };
};
const setupDarkToggle = () => {
    const { hasDarkMode, removeDarkMode, setDarkMode } = darkModeActions(
        document.documentElement,
    );

    const handleClick: EventListener = () => {
        const isDark = hasDarkMode();
        isDark ? removeDarkMode() : setDarkMode();
        localStorage.setItem('theme', !isDark ? 'dark' : 'light');
    };

    const button = document.querySelector('#dark-mode-toggle');
    button?.addEventListener('click', handleClick);

    if (localStorage.getItem('theme') === 'dark') {
        setDarkMode();
    }
};

(() => {
    setupDarkToggle();
})();
